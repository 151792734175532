import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { useMediaPredicate } from "react-media-hook"

import { Breadcrumb } from "../../anti/breadcrumb/breadcrumb"
import { Cover } from "../../anti/cover/cover"

export const AcfCoverMain = ({ data, breadcrumb, className, title }) => {
  const coverMain = data ? data.coverMain : false
  const resizeScreen = useMediaPredicate("(min-width:768px)")
  const [coverImage, setCoverImage] = useState(coverMain.img.mediaItemUrl)

  useEffect(() => {
      setCoverImage(!resizeScreen && !!coverMain.imgMobile 
        ? coverMain.imgMobile.mediaItemUrl 
        : coverMain.img.mediaItemUrl)
  }, [resizeScreen, coverMain])

  return (
    coverMain && (
      <Cover
        variant="basic"
        theme="dark"
        img={coverImage}
        imgHeight="h-500px h-md-600px w-100"
        imgOverlay={coverMain.imgOverlay}
        label={coverMain.label}
        title={(title && title) || coverMain.title}
        text={coverMain.text}
        breadcrumb={
          breadcrumb !== undefined ? <Breadcrumb path={breadcrumb} /> : null
        }
        className={`${className ? className : ""} ${
          data.attributes ? data.attributes.className : ""
        }`}
        buttons={coverMain.buttons}
      ></Cover>
    )
  )
}
